import React, { Component } from 'react';
import {Box} from './FooterStyles.js';

class Footer extends Component {

  constructor(props) {
    super(props);
  }

render() {

return(
  <Box>
    <p style={{ color: "grey", 
                   textAlign: "center", 
                   marginTop: "-10px" }}>
        Contact pairprog1@gmail.com if you have questions/comments/feature suggestions. Icons from <a href="https://www.flaticon.com/authors/bellowen" title="icons">Flaticon</a>.
    </p>
  </Box>
);
}
}

export default Footer;
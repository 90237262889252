import { Component } from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

class ImageUploader extends Component {
  constructor(props) {
    super(props);
  }

  handleImageChange = (event) => {
    this.props.callbackFunction(event);
  };

  render() {

    return (
      <Container className="imageInput">
        <Row>
          <div className="profileImageContainer">
            {this.props.imageUrl && (
              <Image src={this.props.imageUrl} className="profileImage" />
            )}
          </div>
          {this.props.viewRestricted ? null : (
            <Form.Control type="file" onChange={this.handleImageChange} accept="image/*"/>
          )}
        </Row>
      </Container>
    );
  }
}

ImageUploader.propTypes = {
  callbackFunction: PropTypes.func,
  imageUrl: PropTypes.string,
  viewRestricted: PropTypes.bool,
};

export default ImageUploader;

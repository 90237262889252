import Button from "react-bootstrap/Button";
import { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import myCalendarVideo from "./assets/trimmedself.mov";
import worldCalendarVideo from "./assets/trimmedworld.mov";
import userProfilePic from "./assets/userprofilepage.png";
import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";

class TutorialModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 0,
    };
  }

  goToNextPage = (event) => {
    this.setState((prevState) => ({
      pageNumber: prevState.pageNumber + 1,
    }));
  };

  goToPreviousPage = (event) => {
    this.setState((prevState) => ({
      pageNumber: prevState.pageNumber - 1,
    }));
  };

  render() {
    const page0 = (
      <>
        <Row>
          <p>
            {" "}
            On the
            <span style={{ color: "brown", fontWeight: "bold" }}>
              {" "}
              My Calendar{" "}
            </span>{" "}
            page you can create new dates. You can also see the video dates you
            created and signed up for.
          </p>
        </Row>
        <Row>
          <React.Fragment key={"myCalendarVideo"}>
            <video controls muted>
              <source src={myCalendarVideo} type="video/mp4"></source>
            </video>
          </React.Fragment>
        </Row>
        <Row>
          <Row>
            <Form.Check
              className="tutorialButtons"
              type={"checkbox"}
              id={"tutorialCheckbox"}
              label={"Don't show this tutorial again"}
              onClick={this.props.onDisableTutorialChecked}
            />
          </Row>
          <div className="centerButton tutorialButtons">
            <Button
              className="rowButton"
              variant="primary"
              onClick={this.goToNextPage}
            >
              Next
            </Button>
          </div>
        </Row>
      </>
    );

    const page1 = (
      <>
        <Row>
          <p>
            On the{" "}
            <span style={{ color: "brown", fontWeight: "bold" }}>
              {" "}
              World Calendar{" "}
            </span>{" "}
            page you can see dates other people have created and request to
            join them. You will get an email if your request to join was
            accepted. You can also filter dates by age and gender.
          </p>
        </Row>
        <Row>
          <React.Fragment key={"worldCalendarVideo"}>
            <video controls muted>
              <source src={worldCalendarVideo} type="video/mp4"></source>
            </video>
          </React.Fragment>
        </Row>
        <Row>
          <Row>
            <Form.Check
              className="tutorialButtons"
              type={"checkbox"}
              id={"tutorialCheckbox"}
              label={"Don't show this tutorial again"}
              onClick={this.props.onDisableTutorialChecked}
            />
          </Row>
          <div className="centerButton tutorialButtons">
            <Button
              className="rowButton"
              variant="primary"
              onClick={this.goToPreviousPage}
            >
              Back
            </Button>
            <Button
              className="rowButton"
              variant="primary"
              onClick={this.goToNextPage}
            >
              Next
            </Button>
          </div>
        </Row>
      </>
    );

    const page2 = (
      <>
        <Row>
          <p>
            On the{" "}
            <span style={{ color: "brown", fontWeight: "bold" }}>
              {" "}
              User Profile{" "}
            </span>
            page you can update your user profile. Other people will be able to
            see your profile.
          </p>
        </Row>
        <Row>
          <img src={userProfilePic} alt="loading..." />
        </Row>
        <Row>
          <Row>
            <Form.Check
              className="tutorialButtons"
              type={"checkbox"}
              id={"tutorialCheckbox"}
              label={"Don't show this tutorial again"}
              onClick={this.props.onDisableTutorialChecked}
            />
          </Row>
          <div className="centerButton tutorialButtons">
            <Button
              className="rowButton"
              variant="primary"
              onClick={this.goToPreviousPage}
            >
              Back
            </Button>
          </div>
        </Row>
      </>
    );

    const tutorialComponents = [page0, page1, page2];

    return (
      <Modal
        show={this.props.showModal}
        onHide={this.props.hideModalFunction}
        backdrop={"static"}
        animation={false}
        dialogClassName={"tutorialVideo"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Tutorial</Modal.Title>
        </Modal.Header>
        <Modal.Body md="auto" >
          <Container>{tutorialComponents[this.state.pageNumber]}</Container>
        </Modal.Body>
      </Modal>
    );
  }
}

TutorialModal.propTypes = {
  showModal: PropTypes.bool,
  hideModalFunction: PropTypes.func,
  onDisableTutorialChecked: PropTypes.func,
};

export default TutorialModal;

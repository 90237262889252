import React, { Component } from "react";
import { Container } from "react-bootstrap";
import cdkOutputObject from "./cdk-outputs.json";

class Info extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const schedulerEnabled =
      cdkOutputObject.VideoDateBackendStack.schedulerEnabled === "true";
    const schedulerDiv = (
      <div>
        <h1 className="top-header"> Info </h1>
        <p className="default-row-spacer">
          Welcome! We believe the best and most time-efficient way to know someone is through video chatting. This
          website will help you set up video dates.{" "}
        </p>
        <p className="default-row-spacer">
          To start, navigate to the "Date Scheduler" page and create an account. Then
          create a video date. Other people on the site will see your video date and can
          send a request to join. You can also browse video dates that other
          people created and request to join those. {" "}
        </p>
        <p className="default-row-spacer">
          This website does not moderate or record the video dates.{" "}
        </p>
      </div>
    );

    return (
      <>
        <Container>
          {schedulerDiv}
        </Container>
      </>
    );
  }
}

export default Info;

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";
import { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Accordion from "react-bootstrap/Accordion";
import PaginatedScrollableList from "./PaginatedScrollableList";
import {
  eventsPageSize,
  millisPerHour,
  pairRequestsPageSize,
} from "./constants";

// viewing requests for my event

class ViewRequestsModal extends Component {
  constructor(props) {
    super(props);
  }

  isRequestable(date) {
    const millisLeft = date.getTime() - Date.now();
    if (millisLeft <= 0) {
      return {
        buttonMessage: "Date is in the past, cannot respond to request",
        disabled: true,
      };
    } else if (millisLeft <= millisPerHour * 3) {
      return {
        buttonMessage: "Date is starting soon, cannot respond to request",
        disabled: true,
      };
    } else {
      return {
        acceptMessage: "Accept Request",
        rejectMessage: "Reject Request",
        disabled: false,
      };
    }
  }

  render() {
    const isRequestableObject = this.isRequestable(this.props.startTime);
    var componentsToRender = this.props.pairInfo.map((pairInfo) => {
      var bottomButtons;
      if (pairInfo.pairStatus === "PENDING") {
        bottomButtons = (
          <>
            <Button
              className="rowButton"
              variant="primary"
              disabled={
                this.props.inNetworkCall || isRequestableObject.disabled
              }
              onClick={this.props.acceptPairFunction}
            >
              {isRequestableObject.disabled
                ? isRequestableObject.buttonMessage
                : isRequestableObject.acceptMessage}
            </Button>

            {isRequestableObject.disabled ? null : (
              <Button
                className="rowButton"
                variant="danger"
                disabled={
                  this.props.inNetworkCall || isRequestableObject.disabled
                }
                onClick={this.props.rejectPairFunction}
              >
                {isRequestableObject.rejectMessage}
              </Button>
            )}
          </>
        );
      } else if (pairInfo.pairStatus === "ACCEPTED") {
        bottomButtons = (
          <Button
            className="rowButton"
            variant="outline-success"
            type="submit"
            disabled
          >
            Request accepted
          </Button>
        );
      }

      var shortenedMessage =
        pairInfo.message.length > 50
          ? pairInfo.message.slice(0, 50).concat("...")
          : pairInfo.message;

      return (
        <Accordion.Item eventKey={pairInfo.userId}>
          <Accordion.Header>
            {shortenedMessage}
            {pairInfo.pairStatus === "ACCEPTED" ? (
              <Button
                className="rowButton accordionButton"
                variant="outline-success"
                disabled
              >
                Request accepted
              </Button>
            ) : null}
          </Accordion.Header>
          <Accordion.Body>
            <Form>
              <Form.Group className="mb-3">
                <Button
                  onClick={this.props.showUserProfileModal(pairInfo.userId)}
                  variant="primary"
                >
                  View User Profile
                </Button>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Message from requester</Form.Label>
                <Form.Control className="generalTextArea" as="textarea" value={pairInfo.message} disabled />
              </Form.Group>

              <div className="centerButton">
                {this.props.inNetworkCall ? (
                  <Row xs="auto">
                    <Col className="rightOfTwo">{bottomButtons}</Col>
                    <Col className="leftOfTwo">
                      <Spinner animation="border" />
                    </Col>
                  </Row>
                ) : (
                  bottomButtons
                )}
              </div>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      );
    });

    return (
      <Modal
        show={this.props.showModal}
        onHide={this.props.hideModalFunction}
        backdrop={"static"}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Requests for event: {this.props.eventTitle}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body md="auto">
          {this.props.networkError ? (
            <Alert key="modalAlert" variant="danger" className="top-header">
              <Row>
                <Col className="verticalAlignCol">
                  <div>{this.props.alertText}</div>
                </Col>
              </Row>
            </Alert>
          ) : null}

          <Accordion onSelect={this.props.onAccordionSelect}>
            <PaginatedScrollableList
              componentList={componentsToRender}
              pageSize={pairRequestsPageSize}
            />
          </Accordion>
        </Modal.Body>
      </Modal>
    );
  }
}

ViewRequestsModal.propTypes = {
  showModal: PropTypes.bool,
  hideModalFunction: PropTypes.func,
  acceptPairFunction: PropTypes.func,
  rejectPairFunction: PropTypes.func,
  networkError: PropTypes.bool,
  inNetworkCall: PropTypes.bool,
  pairInfo: PropTypes.array,
  eventTitle: PropTypes.string,
  onAccordionSelect: PropTypes.func,
  alertText: PropTypes.string,
  startTime: PropTypes.object,
  showUserProfileModal: PropTypes.func,
};

export default ViewRequestsModal;

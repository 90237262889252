import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";
import { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";

class ConfirmEventDeletionModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    var confirmedPairExtraText = "";
    if (this.props.pairInfo) {
      this.props.pairInfo.every((pair) => {
        if (pair.pairStatus === "ACCEPTED") {
          confirmedPairExtraText = " You've already confirmed someone to join.";
          return false;
        }
        return true;
      })
    }

    var bottomButtons = (
      <>
        <Button
          className="rowButton"
          variant="danger"
          disabled={this.props.inNetworkCall}
          onClick={this.props.deleteEvent}
          name="deleteConfirmButton"
        >
          Yes
        </Button>
        <Button
          className="rowButton"
          variant="primary"
          disabled={this.props.inNetworkCall}
          onClick={this.props.hideDeleteConfirmationModal}
          name="noDeleteButton"
        >
          No
        </Button>
      </>
    );

    return (
      <Modal
        show={this.props.showDeleteEventConfirmationModal}
        onHide={this.props.hideDeleteConfirmationModal}
        backdrop={"static"}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Event Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body md="auto">
          {this.props.networkError ? (
            <Alert key="modalAlert" variant="danger" className="top-header">
              <Row>
                <Col className="verticalAlignCol">
                  <div>{this.props.alertText}</div>
                </Col>
              </Row>
            </Alert>
          ) : null}
          {`Are you sure you want to delete this date?${confirmedPairExtraText}`}
          <div className="centerButton topSpacer">
            {this.props.inNetworkCall ? (
              <Row xs="auto">
                <Col className="rightOfTwo">{bottomButtons}</Col>
                <Col className="leftOfTwo">
                  <Spinner animation="border" />
                </Col>
              </Row>
            ) : (
              bottomButtons
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

ConfirmEventDeletionModal.propTypes = {
  showDeleteEventConfirmationModal: PropTypes.bool,
  hideDeleteConfirmationModal: PropTypes.func,
  deleteEvent: PropTypes.func,
  networkError: PropTypes.bool,
  inNetworkCall: PropTypes.bool,
  alertText: PropTypes.string,
  pairInfo: PropTypes.array
};

export default ConfirmEventDeletionModal;

import Container from "react-bootstrap/Container";
import "./App.css";
import { Component } from "react";
import { Navbar, Spinner } from "react-bootstrap";
import cdkOutputObject from "./cdk-outputs.json";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Info from "./Info";
import logo from "./logo.png";
import ReactGA from "react-ga4";
import {
  googleLibraries,
  loggedOutMessage,
  notLoggedInMessage,
} from "./constants";
import Login from "./Login";
import Scheduler from "./Scheduler";
import UserProfile from "./UserProfile";
import { LoadScript } from "@react-google-maps/api";
import Footer from "./Footer";

if (cdkOutputObject.VideoDateBackendStack.GAProprerty) {
  ReactGA.initialize("G-182GJXBK5E");
  ReactGA.send("pageview");
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "scheduler",

      // cognito login stuff
      accessToken: null,
      idToken: null,
      loggedIn: false,
      loginMessage: notLoggedInMessage,

      // feature flag
      schedulerEnabled:
        cdkOutputObject.VideoDateBackendStack.schedulerEnabled === "true",

      // tutorial modal in scheduler and user profile pages
      showTutorialModal: true,
      disableTutorialChecked: false,

      // is user profile filled in
      userProfileFilledIn: false,

      // user profile data
      profileData: null
    };
  }

  handleSelectTab = (key) => {
    this.setState({ currentTab: key });
  };

  resetTokensOnLoginTimeout = () => {
    this.setState({
      accessToken: null,
      idToken: null,
      loggedIn: false,
      loginMessage: loggedOutMessage,
    });
  };

  // this is only called if login succeeds
  updateTokensOnLoginSuccess = (accessToken, idToken) => {
    this.setState({
      accessToken: accessToken,
      idToken: idToken,
      loggedIn: true,
    });

    // 59 minute login timeout, give 1 minute buffer against cognito 60 minutes
    setTimeout(this.resetTokensOnLoginTimeout, 60 * 59 * 1000);
  };

  onDisableTutorialChecked = (e) => {
    this.setState({ disableTutorialChecked: e.target.checked });

    // update local storage
    localStorage.setItem(
      "showTutorialModal",
      JSON.stringify(!e.target.checked)
    );
  };

  hideTutorialModalFunction = () => {
    this.setState({ showTutorialModal: false });
  };

  updateProfileDataCallback = (isFilledIn, profileData) => {

    // protect against falsy values
    if (isFilledIn) {
      this.setState({ userProfileFilledIn: true})
    }
    else {
      this.setState({ userProfileFilledIn: false})
    }

    if (profileData) {
      this.setState({ profileData: profileData})
    }
  }

  componentDidMount() {

    // load anything necessary here
    const showTutorialModel = localStorage.getItem("showTutorialModal");
    this.setState({
      showTutorialModal: showTutorialModel
        ? JSON.parse(showTutorialModel)
        : true,
    });
  }

  render() {

    return (
      <>
      <LoadScript
          googleMapsApiKey="AIzaSyCW1aOSa8S-K8KIoG16DFkqtSw2mZtkCMg"
          libraries={googleLibraries}
        >
        <Container fluid>
          <Navbar bg="white" className="titleNavbar justify-content-center">
            <img src={logo} width="30" height="30" className="logo" />
            <Navbar.Brand className="titleText">
              Video Dates
            </Navbar.Brand>
          </Navbar>
          <Tabs
            id="rootTab"
            activeKey={this.state.currentTab}
            onSelect={this.handleSelectTab}
            className="mb-3"
          >
            {this.state.schedulerEnabled ? (
              <Tab eventKey="scheduler" title="Date Scheduler" className="ms-auto">
                <Login
                  loginMessage={this.state.loginMessage}
                  poolId={cdkOutputObject.VideoDateBackendStack.userPoolId}
                  poolClientId={
                    cdkOutputObject.VideoDateBackendStack.userPoolClientId
                  }
                  updateTokensOnLoginSuccess={this.updateTokensOnLoginSuccess}
                  idToken={this.state.idToken}
                  accessToken={this.state.accessToken}
                  key={this.state.idToken}
                  loggedIn={this.state.loggedIn}
                >
                  <Scheduler
                    isVisible={this.state.currentTab === "scheduler"}
                    showTutorialModal={this.state.showTutorialModal}
                    hideTutorialModalFunction={this.hideTutorialModalFunction}
                    onDisableTutorialChecked={this.onDisableTutorialChecked}
                    updateProfileDataCallback={this.updateProfileDataCallback}
                    userProfileFilledIn={this.state.userProfileFilledIn}
                    profileData={this.state.profileData}
                  />
                </Login>
              </Tab>
            ) : null}
            {this.state.schedulerEnabled ? (
              <Tab
                eventKey="userProfile"
                title="User Profile"
                className="ms-auto"
              >
                <Login
                  loginMessage={this.state.loginMessage}
                  poolId={cdkOutputObject.VideoDateBackendStack.userPoolId}
                  poolClientId={
                    cdkOutputObject.VideoDateBackendStack.userPoolClientId
                  }
                  updateTokensOnLoginSuccess={this.updateTokensOnLoginSuccess}
                  idToken={this.state.idToken}
                  accessToken={this.state.accessToken}
                  key={this.state.idToken}
                  loggedIn={this.state.loggedIn}
                >
                  <UserProfile updateProfileDataCallback={this.updateProfileDataCallback} />
                </Login>
              </Tab>
            ) : null}
            <Tab eventKey="info" title="Information" className="ms-auto">
              <Info />
            </Tab>
          </Tabs>
          <Footer />
        </Container>
        </LoadScript>
      </>
    );
  }
}

export default App;

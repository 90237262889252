import { Component } from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import ReactSlider from "react-slider";

class FilteringModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.props.hideModalFunction}
        backdrop={"static"}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter dates</Modal.Title>
        </Modal.Header>
        <Modal.Body md="auto">
          <Form.Group className="mb-3">
            <Form.Label>Filter By Age</Form.Label>
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              value={this.props.ageValues}
              min={18}
              max={100}
              onAfterChange={this.props.handleAgeSliderChange}
            />
            <p style={{ color: "brown", fontWeight: "bold" }}>
              {`Selected ages: ${this.props.ageValues[0]} - ${this.props.ageValues[1]}`}
            </p>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Filter By Gender</Form.Label>
            <div className="mb-3">
              <Form.Check
                type={"checkbox"}
                id={"maleCheckbox"}
                label={"Show video dates created by men"}
                checked={this.props.showMale}
                onClick={this.props.handleCheckboxChange("showMale")}
              />
              <Form.Check
                type={"checkbox"}
                id={"femaleCheckbox"}
                label={"Show video dates created by women"}
                checked={this.props.showFemale}
                onClick={this.props.handleCheckboxChange("showFemale")}
              />
              <Form.Check
                type={"checkbox"}
                id={"nonBinaryCheckbox"}
                label={"Show video dates created by non-binary/other"}
                checked={this.props.showNonBinary}
                onClick={this.props.handleCheckboxChange("showNonBinary")}
              />
            </div>
          </Form.Group>
        </Modal.Body>
      </Modal>
    );
  }
}

FilteringModal.propTypes = {
  showModal: PropTypes.bool,
  hideModalFunction: PropTypes.func,
  handleAgeSliderChange: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  ageValues: PropTypes.array,
  showMale: PropTypes.bool,
  showFemale: PropTypes.bool,
  showNonBinary: PropTypes.bool
};

export default FilteringModal;

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";
import { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";

class RequestJoinModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    var bottomButtons = (
      <Button
        className="rowButton"
        variant="primary"
        type="submit"
        disabled={this.props.inNetworkCall}
      >
        Submit request
      </Button>
    );

    return (
      <Modal
        show={this.props.showModal}
        onHide={this.props.hideModalFunction}
        backdrop={"static"}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add a message to your request</Modal.Title>
        </Modal.Header>
        <Modal.Body md="auto">
          {this.props.networkError ? (
            <Alert key="modalAlert" variant="danger" className="top-header">
              <Row>
                <Col className="verticalAlignCol">
                  <div>{this.props.alertText}</div>
                </Col>
              </Row>
            </Alert>
          ) : null}

          <Form
            noValidate
            validated={this.props.formValidated}
            onSubmit={this.props.submitFunction}
          >
            <Form.Group className="mb-3">
              <Form.Label>Write Message Here</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Write a message about why you want to go on this video date"
                value={this.props.message}
                onChange={this.props.handleMessageChange}
                className="generalTextArea"
                required
              />
              <Form.Control.Feedback type="invalid">
                Message is required.
              </Form.Control.Feedback>
            </Form.Group>

            <div className="centerButton">
              {this.props.inNetworkCall ? (
                <Row xs="auto">
                  <Col className="rightOfTwo">{bottomButtons}</Col>
                  <Col className="leftOfTwo">
                    <Spinner animation="border" />
                  </Col>
                </Row>
              ) : (
                bottomButtons
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

RequestJoinModal.propTypes = {
  showModal: PropTypes.bool,
  hideModalFunction: PropTypes.func,
  submitFunction: PropTypes.func,
  networkError: PropTypes.bool,
  inNetworkCall: PropTypes.bool,
  formValidated: PropTypes.bool,
  message: PropTypes.string,
  handleMessageChange: PropTypes.func,
  alertText: PropTypes.string
};

export default RequestJoinModal;


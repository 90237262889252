import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Component } from "react";
import PropTypes from "prop-types";
import { Container, Spinner } from "react-bootstrap";
import ToggleButton from "react-bootstrap/ToggleButton";
import FixRequiredSelect from "./FixRequiredSelect";
import axios from "axios";
import BaseSelect from "react-select";
import cdkOutputObject from "./cdk-outputs.json";
import { Autocomplete } from "@react-google-maps/api";
import {
  getMyProfileDataFailedText,
  getMyProfileDataStartedText,
  getMyProfileDataSuccessText,
  getUserProfileDataFailedText,
  getUserProfileDataStartedText,
  getUserProfileDataSuccessText,
  updateMyProfileDataFailedText,
  updateMyProfileDataStartedText,
  updateMyProfileDataSuccessText,
  genders,
  imageTooBigText,
  imageWrongTypeText,
} from "./constants";
import ImageUploader from "./ImageUploader";

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aboutText: null,

      // start formvalid set to true to not show error messages on load into user profile page
      formValid: true,
      inNetworkCall: false,
      alertType: null,
      alertText: null,
      imageData: null,
      imageUrl: null,
      currentLocation: null,
      fixedLocationText: null,
      newImage: false,
      gender: null,
      age: null,
    };
    this.autocomplete = null;
  }

  onLoad = (autocomplete) => {
    this.autocomplete = autocomplete;
  };

  onPlaceChanged = () => {
    const place = this.autocomplete.getPlace();
    const newLoc = place.geometry.location;
    this.setState((prevState) => ({
      currentLocation: { lat: newLoc.lat(), lng: newLoc.lng() },
      fixedLocationText: place.formatted_address,
    }));
  };

  handleGenderChange = (event) => {
    this.setState({ gender: event.target.value });
  };

  imageUploadCallback = (event) => {
    var imageFile = event.target.files[0];
    const fileSizeMB = imageFile.size / (1024 * 1024);

    if (!imageFile.type.includes("image")) {
      this.setState({
        alertType: "danger",
        alertText: imageWrongTypeText,
      });
    } else if (fileSizeMB > 4) {
      this.setState({
        alertType: "danger",
        alertText: imageTooBigText,
      });
    } else {
      this.setState({
        alertType: null,
        alertText: null,
        newImage: true,
        imageData: imageFile,
        imageUrl: URL.createObjectURL(imageFile),
      });
    }
  };

  async fetchUserProfileData(userId) {
    this.setState({
      inNetworkCall: true,
      alertType: "primary",
      alertText: getUserProfileDataStartedText,
    });

    try {
      const profileDataResponse = await axios.get(
        cdkOutputObject.VideoDateBackendStack.schedulerUrl + "userProfile",
        {
          headers: { Authorization: `Bearer ${this.props.idToken}` },
          params: {
            userId: userId,
          },
        }
      );

      const profileData = profileDataResponse["data"];

      this.setState({
        inNetworkCall: false,
        aboutText: profileData["aboutText"],
        gender: profileData["gender"],
        age: profileData["age"],
        ...(profileData["imageUrl"]
          ? { imageUrl: profileData["imageUrl"] }
          : null),
        ...(profileData["lat"] && profileData["lng"]
          ? {
              currentLocation: {
                lat: parseFloat(profileData["lat"]),
                lng: parseFloat(profileData["lng"]),
              },
            }
          : null),
        ...(profileData["fixedLocationText"]
          ? { fixedLocationText: profileData["fixedLocationText"] }
          : null),
        alertText: getUserProfileDataSuccessText,
        alertType: "primary",
      });
    } catch (err) {
      console.log(err);
      this.setState({
        inNetworkCall: false,
        alertType: "danger",
        alertText: getUserProfileDataFailedText,
      });
    }
  }

  async fetchMyProfileData() {
    this.setState({
      inNetworkCall: true,
      alertType: "primary",
      alertText: getMyProfileDataStartedText,
    });

    try {
      const myProfileDataResponse = await axios.get(
        cdkOutputObject.VideoDateBackendStack.schedulerUrl + "myUserProfile",
        { headers: { Authorization: `Bearer ${this.props.idToken}` } }
      );
      const myProfileData = myProfileDataResponse["data"];

      this.setState({
        inNetworkCall: false,
        aboutText: myProfileData["aboutText"],
        gender: myProfileData["gender"],
        age: myProfileData["age"],
        ...(myProfileData["imageUrl"]
          ? { imageUrl: myProfileData["imageUrl"] }
          : null),
        ...(myProfileData["lat"] && myProfileData["lng"]
          ? {
              currentLocation: {
                lat: parseFloat(myProfileData["lat"]),
                lng: parseFloat(myProfileData["lng"]),
              },
            }
          : null),
        ...(myProfileData["fixedLocationText"]
          ? { fixedLocationText: myProfileData["fixedLocationText"] }
          : null),
        alertText: getMyProfileDataSuccessText,
        alertType: "primary",
      });

      var profileData = {
        age: myProfileData["age"],
        gender: myProfileData["gender"],
        location: {
          lat: parseFloat(myProfileData["lat"]),
          lng: parseFloat(myProfileData["lng"]),
        },
      };

      this.props.updateProfileDataCallback(
        myProfileData["userProfileFilledIn"],
        profileData
      );
    } catch (err) {
      console.log(err);
      this.setState({
        inNetworkCall: false,
        alertType: "danger",
        alertText: getMyProfileDataFailedText,
      });
    }
  }

  isFormValid() {
    if (
      this.state.aboutText &&
      this.state.age &&
      this.state.currentLocation &&
      this.state.fixedLocationText &&
      this.state.gender &&
      this.state.imageUrl
    ) {
      return true;
    } else {
      return false;
    }
  }

  submitUserProfileChanges = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (this.isFormValid()) {
      this.setState({
        inNetworkCall: true,
        alertType: "primary",
        alertText: updateMyProfileDataStartedText,
        formValid: true,
      });

      // calculate obfuscated coordinates
      var randomWeight = Math.random();
      var randomWeight2 = Math.random();

      const formObject = {
        aboutText: this.state.aboutText,
        gender: this.state.gender,
        age: this.state.age,
        ...(this.state.imageData && this.state.newImage
          ? { imageData: this.state.imageData }
          : null),
        ...(this.state.currentLocation
          ? { lat: this.state.currentLocation.lat.toString() }
          : null),
        ...(this.state.currentLocation
          ? { lng: this.state.currentLocation.lng.toString() }
          : null),
        ...(this.state.currentLocation
          ? {
              obfuscatedLat: (
                this.state.currentLocation.lat +
                randomWeight * (0.01 + 0.01 * randomWeight2)
              ).toString(),
            }
          : null),
        ...(this.state.currentLocation
          ? {
              obfuscatedLng: (
                this.state.currentLocation.lng +
                +(1 - randomWeight) * (0.01 + 0.01 * (1 - randomWeight2))
              ).toString(),
            }
          : null),
        ...(this.state.fixedLocationText
          ? { fixedLocationText: this.state.fixedLocationText }
          : null),
      };

      var formData = new FormData();
      for (var key in formObject) {
        formData.append(key, formObject[key]);
      }

      try {
        await axios.post(
          cdkOutputObject.VideoDateBackendStack.schedulerUrl + "myUserProfile",
          formData,
          {
            headers: {
              Authorization: `Bearer ${this.props.idToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        this.setState({
          inNetworkCall: false,
          alertType: "primary",
          alertText: updateMyProfileDataSuccessText,
        });

        var profileData = {
          age: this.state.age,
          gender: this.state.gender,
          location: this.state.currentLocation,
        };

        this.props.updateProfileDataCallback(true, profileData);
      } catch (err) {
        console.log(err);
        this.setState({
          inNetworkCall: false,
          alertType: "danger",
          alertText: updateMyProfileDataFailedText,
        });
      }
    } else {
      this.setState({
        formValid: false,
      });
    }
  };

  async componentDidMount() {
    // fetch given userId profile data if provided in props, otherwise fetch mine
    if (this.props.userId) {
      await this.fetchUserProfileData(this.props.userId);
    } else {
      await this.fetchMyProfileData();
    }
  }

  handleAgeChange = (event) => {
    this.setState({ age: event.value });
  };

  handleAboutTextChange = (event) => {
    this.setState({ aboutText: event.target.value });
  };

  render() {
    var bottomButtons = (
      <Button
        className="rowButton"
        variant="primary"
        type="submit"
        disabled={this.state.inNetworkCall}
      >
        Update My Profile
      </Button>
    );

    // ages: 18-99
    var ageList = [];
    for (var i = 18; i < 100; i++) {
      ageList.push(i.toString());
    }
    var modifiedAgeList = ageList.map((age) => {
      return { value: age, label: age };
    });
    var selectedAge = [{ value: this.state.age, label: this.state.age }];

    return (
      <>
        <Container>
          {this.state.alertText ? (
            <Alert
              key="topAlert"
              variant={this.state.alertType}
              className="top-header"
            >
              <Row>
                <Col className="verticalAlignCol">
                  <div>{this.state.alertText}</div>
                </Col>
              </Row>
            </Alert>
          ) : null}

          <Form onSubmit={this.submitUserProfileChanges}>
            <Form.Group className="mb-3 userProfileFormGroup">
              <Form.Label>Profile Picture</Form.Label>
              <ImageUploader
                callbackFunction={this.imageUploadCallback}
                imageUrl={this.state.imageUrl}
                viewRestricted={this.props.viewRestricted}
              />
            </Form.Group>

            <Form.Group className="mb-3 userProfileFormGroup">
              <Form.Label>
                {this.props.viewRestricted ? "About" : "About Me"}
              </Form.Label>
              <Form.Control
                className="generalTextArea"
                as="textarea"
                placeholder={
                  this.props.viewRestricted
                    ? "User has not filled out profile yet"
                    : "Describe yourself here. Other people will be able to see this."
                }
                value={this.state.aboutText}
                onChange={this.handleAboutTextChange}
                disabled={this.props.viewRestricted || this.state.inNetworkCall}
              />
            </Form.Group>

            {this.props.viewRestricted ? null : (
              <Form.Group className="mb-3 userProfileFormGroup">
                <Form.Label>Location</Form.Label>
                <Autocomplete
                  onLoad={this.onLoad}
                  onPlaceChanged={this.onPlaceChanged}
                >
                  <>
                    <Form.Control
                      placeholder="Add location (exact location won't be shown to other users)"
                      className="no-validate"
                    />
                    <Form.Control
                      onKeyDown={(event) => {
                        event.preventDefault();
                      }}
                      placeholder="No location selected"
                      value={this.state.fixedLocationText}
                    />
                  </>
                </Autocomplete>
              </Form.Group>
            )}

            <Form.Group className="mb-3 userProfileFormGroup">
              <Form.Label>
                {this.props.viewRestricted ? "Gender" : "My Gender"}
              </Form.Label>
              <Row>
                <ButtonGroup className="regularItem">
                  {genders.map((gender, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`gender-${idx}`}
                      type="radio"
                      disabled={this.props.viewRestricted}
                      variant="outline-primary"
                      name="gender"
                      value={gender.value}
                      checked={this.state.gender === gender.value}
                      onChange={(e) => this.handleGenderChange(e)}
                    >
                      {gender.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3 userProfileFormGroup">
              <Form.Label>
                {this.props.viewRestricted ? "Age" : "My Age"}
              </Form.Label>

              <FixRequiredSelect
                name="ageSelect"
                options={modifiedAgeList}
                isMulti={false}
                onChange={this.handleAgeChange}
                SelectComponent={BaseSelect}
                value={selectedAge}
              />
            </Form.Group>

            {!this.props.viewRestricted && !this.state.formValid ? (
              <Form.Group className="mb-3">
                <Form.Text
                  style={{
                    color: "red",
                  }}
                >
                  Please fill in all parts of your profile.
                </Form.Text>
              </Form.Group>
            ) : null}

            {this.props.viewRestricted ? null : (
              <div className="centerButton userProfileFormGroup">
                {this.state.inNetworkCall ? (
                  <Row xs="auto">
                    <Col className="rightOfTwo">{bottomButtons}</Col>
                    <Col className="leftOfTwo">
                      <Spinner animation="border" />
                    </Col>
                  </Row>
                ) : (
                  bottomButtons
                )}
              </div>
            )}
          </Form>
        </Container>
      </>
    );
  }
}

UserProfile.propTypes = {
  idToken: PropTypes.string,
  viewRestricted: PropTypes.bool,
  userId: PropTypes.string,
  updateProfileDataCallback: PropTypes.func,
};

export default UserProfile;
